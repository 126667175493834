import { Text, VStack } from "@chakra-ui/react";
import React from "react";

const EducationCard = ({ place, title }) => {
  return (
    <VStack alignItems="start" justifyContent="center" spacing="0" w="full">
      <Text
        textTransform="uppercase"
        fontWeight="600"
        fontSize="14px"
        letterSpacing="1px"
      >
        {title}
      </Text>
      <Text
        fontSize="13px"
        fontWeight="500"
        textAlign="right"
        opacity="0.5"
        fontStyle="italic"
        wordBreak="break-word"
      >
        {place}
      </Text>
    </VStack>
  );
};

export default EducationCard;
