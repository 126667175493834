import {
  Box,
  Text,
  VStack,
  useColorMode,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import React from "react";

const CallJulian = () => {
  const { colorMode } = useColorMode();
  return (
    <VStack mb="20">
      <LinkBox>
        <Box
          border="2px solid"
          borderColor={colorMode === "light" ? "black" : "white"}
          rounded="full"
          p="10"
          cursor="pointer"
          _hover={
            colorMode === "light"
              ? {
                  borderColor: "white",
                  bgGradient: "linear(to-t, #ffcdb2, #e5989b)",
                  color: "white",
                  transition: "0.2s",
                }
              : {
                  borderColor: "#1A202C",
                  bgGradient: "linear(to-l, #e0b1cb, #9f86c0)",
                  color: "white",
                  transition: "0.4s",
                }
          }
          zIndex="100"
        >
          <LinkOverlay
            href="mailto:iordachee.iulian@gmail.com"
            title="Collaboration request."
          >
            <Text fontSize="18px" fontWeight="800">
              Soo.. what are you waiting for?
            </Text>
          </LinkOverlay>
        </Box>
      </LinkBox>
    </VStack>
  );
};

export default CallJulian;
