import { Container, GridItem, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Navbar from "../Navbar";

const LayoutDefault = ({ children }) => {
  return (
    <SimpleGrid templateRows="80px 1fr">
      <GridItem>
        <Navbar />
      </GridItem>
      <GridItem>
        <Container maxW="7xl">{children}</Container>
      </GridItem>
    </SimpleGrid>
  );
};

export default LayoutDefault;
