import React, { useEffect } from "react";
import AboutGrid from "../../Components/AboutGrid";
import CallJulian from "../../Components/CallJulian";
import Education from "../../Components/Education";
import Footer from "../../Components/Footer";
import Hero from "../../Components/Hero";
import LatestProjects from "../../Components/LatestProjects";
import MyAdvantages from "../../Components/MyAdvantages";
import Reviews from "../../Components/Reviews";
import LayoutDefault from "../../Layouts/LayoutDefault";

const Home = () => {
  useEffect(() => {
    document.title = "Iulian Iordache - Home";
  }, []);
  return (
    <LayoutDefault>
      <Hero />
      <AboutGrid />
      <MyAdvantages />
      <Education />
      <LatestProjects />
      <Reviews />
      <CallJulian />
      <Footer />
    </LayoutDefault>
  );
};

export default Home;
