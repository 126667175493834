import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      "*": {
        userSelect: "none",
        fontFamily: "Raleway",
      },
      "::-webkit-scrollbar": {
        width: " 10px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        bgGradient:
          props.colorMode === "dark"
            ? "linear(to-t, #e0b1cb, #9f86c0)"
            : "linear(to-t, #ffcdb2, #e5989b)",
        borderRadius: "100px",
      },
    }),
  },
});
