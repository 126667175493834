export const projects = [
  {
    title: "Scherra",
    job: "full stack development",
    image: "1",
    to: "https://scherra.com/",
  },
  {
    title: "Medicbank",
    job: "front end development",
    image: "2",
    to: "https://medicbank.co/",
  },

  {
    title: "Vellum",
    job: "UI/UX & App development",
    image: "3",
    to: "https://apps.apple.com/gb/app/vellum-wallpapers/id1095068317",
  },
];
