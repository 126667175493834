import { Text, VStack } from "@chakra-ui/react";
import React from "react";

const GridSection = ({ title, desc }) => {
  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      maxW={{ base: "300px", sm: "300px", md: "500px", xl: "full" }}
      spacing="3"
    >
      <Text
        textTransform="uppercase"
        fontWeight="600"
        fontSize="14px"
        opacity="0.3"
        letterSpacing="1px"
        textAlign="center"
      >
        {title}
      </Text>
      <Text
        fontSize="16px"
        fontWeight="500"
        textAlign="left"
        wordBreak="break-word"
      >
        {desc}
      </Text>
    </VStack>
  );
};

export default GridSection;
