import { Box, Heading, Text, useColorMode, VStack } from "@chakra-ui/react";
import React from "react";

const SkillCard = ({ title, desc }) => {
  const { colorMode } = useColorMode();
  return (
    <VStack alignItems="center" justifyContent="center">
      <Box
        border="1px dashed"
        borderColor={colorMode === "light" ? "black" : "white"}
        w="125px"
        h="200px"
        rounded="full"
      >
        <VStack
          justifyContent="center"
          alignItems="center"
          h="full"
          spacing="5"
        >
          <Heading fontWeight="600" fontSize="2xl" fontStyle="italic">
            {desc}
          </Heading>
          <Text fontWeight="500" letterSpacing="1px">
            {title}
          </Text>
        </VStack>
      </Box>
    </VStack>
  );
};

export default SkillCard;
