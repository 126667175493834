export const left = [
  {
    title: "BSc Computer Science ( Software Engineering )",
    place: "Brunel University London",
  },
  {
    title: "MSc Computer Science ( Software Engineering )",
    place: "Brunel University London",
  },
];

export const middle = [
  {
    title: "Full Stack App Developer",
    place: "Scherra, UK",
  },
  {
    title: "Front end  Developer",
    place: "Medicbank Ltd, UK",
  },
  {
    title: "Web Developer / UI & Interaction Designer",
    place: "Freelancer",
  },
  {
    title: "CEO & Full Stack Developer",
    place: "Scherra, UK",
  },
];

export const right = [
  {
    title: "Web Developer / Web Designer",
    place: "Freelancer",
  },
  {
    title: "CEO & Full Stack Developer",
    place: "Scherra, UK",
  },
];
