import { GridItem, Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import { projects } from "./data";
import ProjectCard from "./ProjectCard";

const LatestProjects = () => {
  return (
    <VStack mb="20">
      <Heading fontWeight="500" mb="20">
        My latest projects
      </Heading>
      <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} gap="20">
        {projects.map((item, idx) => (
          <GridItem key={idx}>
            <ProjectCard
              title={item.title}
              job={item.job}
              image={item.image}
              to={item.to}
            />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default LatestProjects;
