import { Heading, useColorMode, VStack } from "@chakra-ui/react";
import React from "react";

const Reviews = () => {
  const { colorMode } = useColorMode();
  return (
    <VStack my="60" alignItems="center" justifyContent="center">
      <Heading
        textAlign="center"
        fontStyle="italic"
        opacity="0.8"
        color={colorMode === "light" ? "black" : "white"}
      >
        " Iulian was a real pleasure to work with and we look forward to working
        with him again. He's definitely the kind of developer you can trust with
        a project from start to finish. Great services. Recommended "
      </Heading>
    </VStack>
  );
};

export default Reviews;
