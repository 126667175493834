import {
  Box,
  useColorMode,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import LayoutDefault from "../../Layouts/LayoutDefault";
import { Link as RouterLink } from "react-router-dom";

const NotFound = () => {
  useEffect(() => {
    document.title = "Iulian Iordache - Not found";
  }, []);

  const { colorMode } = useColorMode();

  console.log(colorMode);
  return (
    <LayoutDefault>
      <VStack mt="20" spacing="10">
        <HStack spacing="3">
          <Heading>404</Heading>
          <Box
            bg={colorMode === "light" ? "black" : "white"}
            w="2px"
            h="40px"
          />
          <Text w="200px">The page you are looking for was not found</Text>
        </HStack>
        <Link
          as={RouterLink}
          to="/home"
          _hover={{
            textDecoration: "none",
            bg: "black",
            color: "white",
          }}
          _focus={{ border: "none" }}
          px="10"
          py="3"
          fontWeight="700"
          rounded="full"
          color="black"
          border="2px solid black"
        >
          Go back home
        </Link>
      </VStack>
    </LayoutDefault>
  );
};

export default NotFound;
