import { Heading, VStack } from "@chakra-ui/react";
import React from "react";

const Hero = () => {
  return (
    <VStack my="20">
      <Heading>Iulian Iordache</Heading>
      <Heading fontWeight="300" textAlign="center">
        Web Developer & UI/UX Interaction Designer
      </Heading>
      <Heading fontWeight="100">Based in the United Kingdom</Heading>
    </VStack>
  );
};

export default Hero;
