import {
  Divider,
  GridItem,
  HStack,
  IconButton,
  Link,
  SimpleGrid,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { RiGithubFill, RiInstagramFill, RiTwitterFill } from "react-icons/ri";

const Footer = () => {
  const { colorMode } = useColorMode();
  return (
    <VStack mb="3">
      <Divider
        borderWidth="1px"
        borderColor={
          colorMode === "light" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.3)"
        }
        rounded="full"
        mb="3"
      />
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 3 }}
        templateColumns={{
          base: "1fr",
          md: "1fr",
          lg: "repeat(3,1fr)",
        }}
        w="full"
        h="50px"
        placeItems="center"
      >
        <GridItem>
          <Text textTransform="uppercase" fontSize="12px" opacity="0.5">
            &copy; 2022 iulian iordache, all righ reserved
          </Text>
        </GridItem>

        <GridItem>
          <Link
            textTransform="uppercase"
            letterSpacing="1px"
            fontWeight="400"
            fontSize="14px"
          >
            @iordachee.iulian
          </Link>
        </GridItem>

        <GridItem
          display={{
            base: "none",
            lg: "inline",
          }}
        >
          <HStack spacing={{ base: 5, sm: 10 }}>
            <IconButton
              variant="ghost"
              rounded="full"
              fontSize="1.4rem"
              aria-label="instagram socials"
              _focus={{ border: "none" }}
              icon={<RiInstagramFill />}
            />
            <IconButton
              variant="ghost"
              rounded="full"
              fontSize="1.4rem"
              _focus={{ border: "none" }}
              aria-label="twitter socials"
              icon={<RiGithubFill />}
            />
            <IconButton
              variant="ghost"
              rounded="full"
              fontSize="1.4rem"
              _focus={{ border: "none" }}
              aria-label="twitter socials"
              icon={<RiTwitterFill />}
            />
          </HStack>
        </GridItem>
      </SimpleGrid>
    </VStack>
  );
};

export default Footer;
