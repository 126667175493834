import { Heading, Link, Text, VStack, useColorMode } from "@chakra-ui/react";
import React from "react";

const ProjectCard = ({ title, job, image, to }) => {
  const { colorMode } = useColorMode();
  return (
    <VStack w="full" h="full">
      <VStack
        justifyContent="center"
        alignItems="center"
        w="350px"
        h="350px"
        bg={colorMode === "light" ? "#212529" : "#adb5bd"}
      >
        <Heading fontSize="18rem" color="white" opacity="0.1">
          {image}
        </Heading>
      </VStack>

      <VStack alignItems="start" justifyContent="center" w="full" spacing="3">
        <Text
          fontSize="11px"
          fontWeight="700"
          opacity="0.4"
          wordBreak="break-word"
          textTransform="uppercase"
        >
          {job}
        </Text>
        <Link
          textTransform="uppercase"
          fontWeight="700"
          fontSize="16px"
          href={to}
          target="_blank"
        >
          {title}
        </Link>
      </VStack>
    </VStack>
  );
};

export default ProjectCard;
