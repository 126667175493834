export const leftSide = [
  {
    title: "biography",
    desc: "I love a good cup of #000000 coffee in the morning! Hi I'm Iulian, a passionate Web Developer and Designer based in the United Kingdom.",
  },
  {
    title: "contact",
    desc: "London, United Kingdom, iordachee.iulian@gmail.com, +44 7552 931 415.",
  },
  {
    title: "services",
    desc: "I offer a wide range of services, from design to development, from UI/UX to full code implementation, from wireframes to prototypes to production. I also offer a range of services for the development of mobile apps.",
  },
];

export const rightSide = [
  {
    title: "years of experience",
    desc: "I have been working as a UI/UX Interaction Designer for over 5 years and as a Developer for 2 years.",
  },
  {
    title: "current clients",
    desc: "I have worked with a range of clients, from small startups to large companies. Currently I have over 20 active clients.",
  },
  {
    title: "projects done",
    desc: "I have worked on a range of projects, from small to large, from landing pages to full stack websites and have completed over 200 projects.",
  },
];
