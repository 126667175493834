import {
  Box,
  GridItem,
  Image,
  SimpleGrid,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import GridSection from "./GridSection";
import { leftSide, rightSide } from "./data";
import logo from "../../Images/mainmoji3.png";

const AboutGrid = () => {
  const { colorMode } = useColorMode();
  return (
    <SimpleGrid columns={{ md: 1, xl: 3 }} spacing="14" mb="5">
      <GridItem>
        <VStack
          w="full"
          h="full"
          justifyContent="center"
          alignItems="center"
          spacing="14"
        >
          {leftSide.map((item, idx) => (
            <GridSection key={idx} title={item.title} desc={item.desc} />
          ))}
        </VStack>
      </GridItem>

      <GridItem>
        <VStack justifyContent="center" alignItems="center">
          <Box
            w={{ base: "275px", xl: "375px" }}
            h={{ base: "500px", xl: "600px" }}
            rounded="full"
            bgGradient={
              colorMode === "light"
                ? "linear(to-t, #ffcdb2, #e5989b)"
                : "linear(to-t, #e0b1cb, #9f86c0)"
            }
          >
            <VStack
              h="full"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={logo} />
            </VStack>
          </Box>
        </VStack>
      </GridItem>

      <GridItem>
        <VStack
          h="full"
          w="full"
          justifyContent="center"
          alignItems="center"
          spacing="14"
        >
          {rightSide.map((item, idx) => (
            <GridSection key={idx} title={item.title} desc={item.desc} />
          ))}
        </VStack>
      </GridItem>
    </SimpleGrid>
  );
};

export default AboutGrid;
