export const skills = [
  {
    title: "ReactJS",
    desc: "100%",
    icon: "",
  },
  {
    title: "Express",
    desc: "80%",
    icon: "",
  },
  {
    title: "Figma",
    desc: "75%",
    icon: "",
  },
  {
    title: "Illustrator",
    desc: "60%",
    icon: "",
  },
  {
    title: "Photoshop",
    desc: "50%",
    icon: "",
  },
  {
    title: "HTML / CSS ",
    desc: "100%",
    icon: "",
  },
];
