import {
  useColorMode,
  Divider,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { left, middle, right } from "./data";
import EducationCard from "./EducationCard";

const Education = () => {
  const { colorMode } = useColorMode();
  return (
    <VStack w="full" mb="20" mt="40">
      <Divider
        borderWidth="1px"
        borderColor={
          colorMode === "light" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.3)"
        }
        rounded="full"
      />
      <Heading pt="20" pb="20" fontWeight="500">
        Education & Work experience
      </Heading>
      <VStack w="full" pb="14">
        <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} gap="20">
          <GridItem>
            <Text
              textTransform="uppercase"
              fontWeight="600"
              fontSize="14px"
              opacity="0.4"
              letterSpacing="1px"
              pb="7"
            >
              2018
            </Text>
            <VStack justifyContent="center" alignItems="start" spacing="5">
              {left.map((item, idx) => (
                <EducationCard
                  key={idx}
                  title={item.title}
                  place={item.place}
                />
              ))}
            </VStack>
          </GridItem>
          <GridItem>
            <Text
              textTransform="uppercase"
              fontWeight="600"
              fontSize="14px"
              opacity="0.4"
              letterSpacing="1px"
              pb="7"
            >
              2018 - 2020
            </Text>
            <VStack justifyContent="center" alignItems="start" spacing="5">
              {middle.map((item, idx) => (
                <EducationCard
                  key={idx}
                  title={item.title}
                  place={item.place}
                />
              ))}
            </VStack>
          </GridItem>
          <GridItem>
            <Text
              fontWeight="600"
              fontSize="14px"
              opacity="0.4"
              letterSpacing="1px"
              pb="7"
            >
              2021 - present
            </Text>
            <VStack justifyContent="center" alignItems="start" spacing="5">
              {right.map((item, idx) => (
                <EducationCard
                  key={idx}
                  title={item.title}
                  place={item.place}
                />
              ))}
            </VStack>
          </GridItem>
        </SimpleGrid>
      </VStack>
      <Divider
        borderWidth="1px"
        borderColor={
          colorMode === "light" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.3)"
        }
        rounded="full"
      />
    </VStack>
  );
};

export default Education;
