import { Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import { skills } from "./data";
import SkillCard from "./SkillCard";

const MyAdvantages = () => {
  return (
    <VStack mt="60" scrollSnapType="block" spacing="20">
      <Heading fontWeight="500">My skills</Heading>
      <SimpleGrid columns={{ base: 2, md: 3, xl: 6 }} gap="10">
        {skills.map((skill, idx) => (
          <SkillCard key={idx} title={skill.title} desc={skill.desc} />
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default MyAdvantages;
