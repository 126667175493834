import {
  Avatar,
  Box,
  GridItem,
  HStack,
  IconButton,
  Link,
  SimpleGrid,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { RiGithubFill, RiInstagramFill, RiTwitterFill } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <SimpleGrid
      columns={{ base: 1, md: 1, lg: 3 }}
      templateColumns={{
        base: "1fr",
        md: "1fr",
        lg: "repeat(3,1fr)",
      }}
      placeItems="center"
      w="full"
      h="full"
    >
      <GridItem>
        <HStack spacing={{ base: 5, sm: 10 }}>
          <Link
            as={RouterLink}
            to="/projects"
            fontSize="14px"
            fontWeight="800"
            letterSpacing="1px"
            _hover={{
              textDecoration: "none",
            }}
            _focus={{ border: "none" }}
          >
            PROJECTS
          </Link>
          <Link
            as={RouterLink}
            to="/contact"
            fontSize="14px"
            fontWeight="800"
            letterSpacing="1px"
            _hover={{
              textDecoration: "none",
            }}
            _focus={{ border: "none" }}
          >
            CONTACT
          </Link>
        </HStack>
      </GridItem>

      <GridItem
        display={{
          base: "none",
          lg: "inline",
        }}
      >
        <HStack alignItems="center" justifyContent="center" w="full" h="full">
          <Box
            rounded="full"
            onClick={toggleColorMode}
            cursor="pointer"
            border="2px black dashed"
            borderColor={colorMode === "light" ? "black" : "white"}
            p="0.5"
            transition="0.5s"
          >
            <Avatar
              name="Iulian Iordache"
              bg={colorMode === "light" ? "black" : "white"}
              color={colorMode === "light" ? "white" : "black"}
              transition="0.5s"
              size="lg"
            />
          </Box>
        </HStack>
      </GridItem>

      <GridItem
        display={{
          base: "none",
          lg: "inline",
        }}
      >
        <HStack spacing={{ base: 5, sm: 10 }}>
          <IconButton
            variant="solid"
            rounded="full"
            fontSize="1.4rem"
            aria-label="instagram socials"
            _focus={{ border: "none" }}
            icon={<RiInstagramFill />}
          />
          <IconButton
            variant="solid"
            rounded="full"
            fontSize="1.4rem"
            _focus={{ border: "none" }}
            aria-label="twitter socials"
            icon={<RiGithubFill />}
          />
          <IconButton
            variant="solid"
            rounded="full"
            fontSize="1.4rem"
            _focus={{ border: "none" }}
            aria-label="twitter socials"
            icon={<RiTwitterFill />}
          />
        </HStack>
      </GridItem>
      {/* <HStack
      h="full"
      w="full"
      alignItems="center"
      justifyContent="space-between"
      px={{ base: 5, sm: 10 }}
    >
      

      
      
    </HStack> */}
    </SimpleGrid>
  );
};

export default Navbar;
